import { Link } from 'react-router-dom'
import Footer from "../Footer"
import IconLoc from '../../img/icon-location-light.svg'
import NavbarDc from '../navbar/Navbar-dc'
import img00 from '../../img/domaine-cheongdam/dc-img01.png'
import img01 from '../../img/domaine-cheongdam/dc-img02.png'
import img02 from '../../img/domaine-cheongdam/dc-img03.png'
import img03 from '../../img/domaine-cheongdam/dc-img04.png'
import img04 from '../../img/domaine-cheongdam/dc-img05.png'
import img05 from '../../img/domaine-cheongdam/dc-img06.png'
import ser00 from '../../img/domaine-cheongdam/ser00.svg'
import ser01 from '../../img/domaine-cheongdam/ser01.svg'
import ser02 from '../../img/domaine-cheongdam/ser02.svg'
import ser03 from '../../img/domaine-cheongdam/ser03.svg'

const Domaine = () => {
    return (
        <>
        <NavbarDc/>
        <div id="anc-domaine" className="sec-domaine">
            <div>
                <div className="div-domaine">
                    <div className="div-domaine-bg"></div>
                    <div className="hero-dom">
                        <h1 className="domaine-h1">
                            와인과 다이닝의 환상적인 마리아주를 경험할 수 있는 최고의 공간
                        </h1>
                        <a href="https://domainecheongdam.com/" target="_blank" className="hero-btn">
                            <span className="hero-btn-txt">웹사이트</span>
                            <svg className="hero-arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 14L14 10L10 6L8.6 7.4L10.2 9H6V11H10.2L8.6 12.6L10 14ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <main className="main-dom">
                <div className="sec-dom-gal">
                    <div className="dom-gal-top">
                        <p className="dom-gal-title">
                            EXTRAORDINARY<br/>MOMENTS WITH WINE
                        </p>
                        <p className="dom-gal-desc">
                            도멘청담은 부르고뉴의 와이너리를 옮겨놓은 듯한 공간에서
                            와인과 음식에 대해 진심인 최고의 크루들이
                            환상의 마리아주를 경험할 수 있도록 서비스를 제공합니다.
                        </p>
                    </div>
                    <div className="dom-gal-bot">
                        <div className="dom-gallery">
                            <div className="dom-galleries-div">
                                <img src={img00} className="dom-galleries"/>
                            </div>
                            <div className="dom-galleries-div">
                                <img src={img01} className="dom-galleries"/>
                            </div>
                            <div className="dom-galleries-div">
                                <img src={img02} className="dom-galleries"/>
                            </div>
                            <div className="dom-galleries-div">
                                <img src={img03} className="dom-galleries"/>
                            </div>
                            <div className="dom-galleries-div">
                                <img src={img04} className="dom-galleries"/>
                            </div>
                            <div className="dom-galleries-div">
                                <img src={img05} className="dom-galleries"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="sec-dom-services">*/}
                {/*    <div className="div-dom-services">*/}
                {/*        <p className="dom-services-title">*/}
                {/*            TASTE<br/>*/}
                {/*            BY DOMAINE CHEONGDAM*/}
                {/*        </p>*/}
                {/*        <p className="dom-services-desc">*/}
                {/*            프리미엄 와인들과 최고의 마리아주를 경험해보세요*/}
                {/*        </p>*/}
                {/*        <div className="dom-services-list">*/}
                {/*            <div className="dom-services-lists">*/}
                {/*                <img src={ser00} className="dom-services-img"/>*/}
                {/*                <p className="dom-services-type">*/}
                {/*                    Wine Tasting*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*            <div className="dom-services-lists">*/}
                {/*                <img src={ser01} className="dom-services-img"/>*/}
                {/*                <p className="dom-services-type">*/}
                {/*                    Wine Concierge Service*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*            <div className="dom-services-lists">*/}
                {/*                <img src={ser02} className="dom-services-img"/>*/}
                {/*                <p className="dom-services-type">*/}
                {/*                    Private Membership*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*            <div className="dom-services-lists">*/}
                {/*                <img src={ser03} className="dom-services-img"/>*/}
                {/*                <p className="dom-services-type">*/}
                {/*                    Fine Dining*/}
                {/*                </p>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </main>

            {/* Location */}
            <div></div>

            <div className="div-loc">
                <h2 className="loc-title">
                    매장안내
                </h2>
                <div className="loc-content">
                    <div className="loc-desc">
                        <p className="font-bold">
                            도멘청담
                        </p>
                        <p>
                            서울 강남구 도산대로57길 8
                        </p>
                        <p>
                            010-2760-0222
                        </p>
                    </div>
                    <div className="loc-btn">
                        <a href="https://map.naver.com/v5/search/%EC%84%9C%EC%9A%B8%20%EA%B0%95%EB%82%A8%EA%B5%AC%20%EB%8F%84%EC%82%B0%EB%8C%80%EB%A1%9C57%EA%B8%B8%208(%EC%B2%AD%EB%8B%B4%EB%8F%99%2091%20%EC%8A%B9%EC%9B%90%EB%B9%8C%EB%94%A9%201%EC%B8%B5)/address/14142190.024576802,4512419.218659455,%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%20%EA%B0%95%EB%82%A8%EA%B5%AC%20%EB%8F%84%EC%82%B0%EB%8C%80%EB%A1%9C57%EA%B8%B8%208,new?c=14142158.2985219,4512420.0888948,19,0,0,0,dh&isCorrectAnswer=true"
                           target="_blank"
                           rel="noopener noreferrer"
                           className="loc-btns"
                        >
                            <img
                                src={IconLoc}
                                alt="location icon" />
                            <p className="text-black">
                                네이버
                            </p>
                        </a>
                        <a
                            href="https://map.kakao.com/?map_type=TYPE_MAP&q=%EC%84%9C%EC%9A%B8+%EA%B0%95%EB%82%A8%EA%B5%AC+%EB%8F%84%EC%82%B0%EB%8C%80%EB%A1%9C57%EA%B8%B8+8(%EC%B2%AD%EB%8B%B4%EB%8F%99+91+%EC%8A%B9%EC%9B%90%EB%B9%8C%EB%94%A9+1%EC%B8%B5)&urlLevel=2&urlX=509157&urlY=1117960"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="loc-btns"
                        >
                            <img
                                src={IconLoc}
                                alt="location icon" />
                            <p className="text-black">
                                카카오
                            </p>
                        </a>
                        <a
                            href="https://www.google.com/maps/place/%EB%8F%84%EB%A9%98%EC%B2%AD%EB%8B%B4/@37.5240847,127.0392211,17z/data=!3m1!4b1!4m5!3m4!1s0x357ca58cf35be33f:0xa9666704861b79a4!8m2!3d37.5240847!4d127.0414098"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="loc-btns"
                        >
                            <img
                                src={IconLoc}
                                alt="location icon"
                                className="icon-loc"
                            />
                            <p className="text-black">
                                구글
                            </p>
                        </a>
                    </div>
                </div>
            </div>

            <div></div>
            <Footer />
        </div>
        </>
    )
}

export default Domaine