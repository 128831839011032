import { Link } from 'react-router-dom'
import Footer from "../Footer"
import IconLoc from '../../img/icon-location-light.svg'
import NavbarCh from '../navbar/Navbar-ch'
import img00 from '../../img/cheongdam-haejang/ch-img00.png'
import img01 from '../../img/cheongdam-haejang/ch-img01.png'
import img02 from '../../img/cheongdam-haejang/ch-img02.png'
import img03 from '../../img/cheongdam-haejang/ch-img03.png'
import img04 from '../../img/cheongdam-haejang/ch-img04.png'
import img05 from '../../img/cheongdam-haejang/ch-img05.png'

const Cheongdam = () => {
    return (
        <>
            <NavbarCh/>
            <div id="anc-cheongdam" className="sec-domaine">
                <div>
                    <div className="div-cheongdam">
                        <div className="div-domaine-bg"></div>
                        <div className="hero-dom">
                            <h1 className="kappo-h1">
                                청담동에서 먹는 고급스럽고<br/>
                                깔끔한 선지해장국
                            </h1>
                            <h1 className="kappo-h1 mobilet">
                                청담동에서 먹는 고급스럽고
                                깔끔한 선지해장국
                            </h1>
                            {/*<p className="hero-desc">*/}
                            {/*    DOMAINE CHEONGDAM*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                <main className="main-dom">
                    <div className="sec-dom-gal">
                        <div className="dom-gal-top">
                            <p className="dom-gal-title">
                                TASTE<br/>
                                OF KOREA
                            </p>
                            <p className="dom-gal-desc">
                                청담해장국은 엄선된 재료로 정성을 다해 만들어 깊고 구수한 국밥의 국물 맛으로 고객의 하루 시작을 든든하게 해드립니다.
                            </p>
                        </div>
                        <div className="dom-gal-bot">
                            <div className="dom-gallery">
                                <div className="dom-galleries-div">
                                    <img src={img00} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img01} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img02} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img03} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img04} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img05} className="dom-galleries"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="sec-dom-services">*/}
                    {/*    <div className="div-dom-services">*/}
                    {/*        <p className="dom-services-title">*/}
                    {/*            TASTE<br/>*/}
                    {/*            BY DOMAINE CHEONGDAM*/}
                    {/*        </p>*/}
                    {/*        <p className="dom-services-desc">*/}
                    {/*            프리미엄 와인들과 최고의 마리아주를 경험해보세요*/}
                    {/*        </p>*/}
                    {/*        /!*<div className="dom-services-list">*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser00} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Tasting*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser01} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Concierge Service*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser02} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Private Membership*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser03} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Fine Dining*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </main>

                {/* Location */}
                <div></div>

                <div className="div-loc">
                    <h2 className="loc-title">
                        매장안내
                    </h2>
                    <div className="loc-content">
                        <div className="loc-desc">
                            <p className="font-bold">
                                청담해장국 by 압구정로데오점
                            </p>
                            <p>
                                서울 강남구 언주로172길 53 청담해장국
                            </p>
                            <p>
                                0507-1475-6988
                            </p>
                        </div>
                        <div className="loc-btn">
                            <a href="https://map.naver.com/v5/entry/place/1391491959?placePath=%2Fhome"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon" />
                                <p className="text-black">
                                    네이버
                                </p>
                            </a>
                            <a
                                href="https://map.kakao.com/?map_type=TYPE_MAP&q=%EC%B2%AD%EB%8B%B4%ED%95%B4%EC%9E%A5%EA%B5%AD&hId=1094911787&mode=place&urlLevel=3&urlX=508450&urlY=1118773"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon" />
                                <p className="text-black">
                                    카카오
                                </p>
                            </a>
                            <a
                                href="https://goo.gl/maps/mb5YeM7zwZ7aJKaS8"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon"
                                    className="icon-loc"
                                />
                                <p className="text-black">
                                    구글
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div></div>
                <Footer />
            </div>
        </>
    )
}

export default Cheongdam