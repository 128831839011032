import { Routes, Route } from 'react-router-dom'
import './App.css';
import './Mobile.css';
import Home from './components/Home'
import Domaine from './components/services/Domaine'
import Cheongdam from './components/services/Cheongdam'
import Kappo from './components/services/Kappo'
import Biraeok from './components/services/Biraeok'

function App() {
  return (
      <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="domaine-cheongdam" element={<Domaine/>}></Route>
          <Route path="1974-biraeok" element={<Biraeok/>}></Route>
          <Route path="cheongdam-haejang" element={<Cheongdam/>}></Route>
          <Route path="kappo-syun" element={<Kappo/>}></Route>
      </Routes>
  );
}

export default App;
