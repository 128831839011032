import { Link } from 'react-router-dom'
import Logo from '../../img/logo-monofnb.svg'
import LogoIcon from '../../img/DH_icon.svg'
import LogoDC from '../../img/logo-domaine.svg'
import x from '../../img/x.svg'
import Hamb from '../../img/iconOpen.png'
import Close from '../../img/iconClose.png'
import React, {useState} from 'react';

const NavbarDc = () => {

    const [toggleState,setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <>
            <div id="desktop" className="sec-nav">
                <div className="div-nav">
                    <div className="nav-logo">
                        <Link className="nav-logo-mono" to="/">
                            <img src={Logo} />
                        </Link>
                        <img src={x} className="nav-logo-division"></img>
                        <img src={LogoDC} className="nav-logo-domaine"/>
                    </div>

                    <div className="nav-menu">
                        <a href="/domaine-cheongdam/#anc-domaine" className="nav-menus">
                            도멘청담
                        </a>
                        <a href="/1974-biraeok/#anc-biraeok" className="nav-menus">
                            1974 비래옥
                        </a>
                        <a href="/kappo-syun/#anc-kappo" className="nav-menus">
                            갓포슌
                        </a>
                        {/*<a href="/cheongdam-haejang/#anc-cheongdam" className="nav-menus">*/}
                        {/*    청담 해장국*/}
                        {/*</a>*/}
                        <div className="nav-division"></div>
                        <a href="mailto:contact@domaineholdings.com" className="nav-menus">
                            문의하기
                        </a>
                    </div>
                </div>
            </div>
            <div id="mobile" className="sec-mnav">
                <div className="div-mnav">
                    <div className="mnav-left">
                        <img src={LogoIcon} className="mnav-logo"/>
                        <img src={x} className="mnav-division"/>
                        <img src={LogoDC} className="mnav-dom-logo"/>
                    </div>
                    <div className="mnav-right">
                        <img src={Hamb} onClick={()=>toggleTab(1)} className={toggleState === 0 ? "mnav-icon" : "mnav-icon-out"}/>
                        <img src={Close} onClick={()=>toggleTab(0)} className={toggleState === 1 ? "mnav-icon" : "mnav-icon-out"}/>
                    </div>
                </div>
            </div>
            <div className={toggleState === 1 ? "div-mnav-sub" : "div-mnav-sub off"}>
                <div className="div-mnav-sub-list">
                    <a href="/domaine-cheongdam/#anc-domaine" className="nav-menus" onClick={()=>toggleTab(0)}>
                        도멘청담
                    </a>
                    <a href="/1974-biraeok/#anc-biraeok" className="nav-menus" onClick={()=>toggleTab(0)}>
                        1974 비래옥
                    </a>
                    <a href="/kappo-syun/#anc-kappo" className="nav-menus" onClick={()=>toggleTab(0)}>
                        갓포슌
                    </a>
                    {/*<a href="/cheongdam-haejang/#anc-cheongdam" className="nav-menus" onClick={()=>toggleTab(0)}>*/}
                    {/*    청담 해장국*/}
                    {/*</a>*/}
                    <div className="nav-division"></div>
                    <a href="mailto:contact@domaineholdings.com" className="nav-menus" onClick={()=>toggleTab(0)}>
                        문의하기
                    </a>
                </div>
            </div>
        </>
    )
}

export default NavbarDc