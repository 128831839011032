import { Link } from 'react-router-dom'
import HImg00 from "../img/hero-img-domaine.png"
import HImg01 from "../img/hero-img-biraeok2.png"
import HImg02 from "../img/hero-img-kappo.png"
import HImg03 from "../img/hero-img-cheongdam.png"
import HLogo00 from "../img/logo-domaine.svg"
import HLogo01 from "../img/logo-biraeok.png"
import HLogo02 from "../img/logo-kappo.svg"
import HLogo03 from "../img/logo-cheongdam.svg"
import Opening from "./Opening"

const Home = () => {
    return (
        <>
            <Opening />
            <div className="sec-main">
                <Link to="/domaine-cheongdam" className="div-main-sec">
                    <div className="main-div-sec"></div>
                    <img
                        src={HImg00}
                        alt="도멘청담 - 파인다이닝 와인바"
                        className="home-bg"
                    />
                    <img
                        src={HLogo00}
                        alt="도멘청담 로고"
                        className="main-logo-dom"
                    />
                </Link>
                <Link to="/1974-biraeok" className="div-main-sec">
                    <div className="main-div-sec"></div>
                    <img
                        src={HImg01}
                        alt="옥"
                        className="home-bg"
                    />
                    <img
                        src={HLogo01}
                        alt="비래옥 로고"
                        className="main-logo-signal"
                    />
                </Link>
                <Link to="/kappo-syun" className="div-main-sec">
                    <div className="main-div-sec"></div>
                    <img
                        src={HImg02}
                        alt="가포슌 - 이자카야"
                        className="home-bg"
                    />
                    <img
                        src={HLogo02}
                        alt="가포슌 로고"
                        className="main-logo-kappo"
                    />
                </Link>
                {/*<Link to="cheongdam-haejang" className="div-main-sec">*/}
                {/*    <div className="main-div-sec"></div>*/}
                {/*    <img*/}
                {/*        src={HImg03}*/}
                {/*        alt="청담해장국 - 해장국"*/}
                {/*        className="home-bg"*/}
                {/*    />*/}
                {/*    <img*/}
                {/*        src={HLogo03}*/}
                {/*        alt="청담해장국 로고"*/}
                {/*        className="main-logo-cheong"*/}
                {/*    />*/}
                {/*</Link>*/}
            </div>
        </>
    )
}

export default Home