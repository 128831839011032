import { Link } from 'react-router-dom'
import Footer from "../Footer"
import IconLoc from '../../img/icon-location-light.svg'
import NavbarKs from '../navbar/Navbar-ks'
import img00 from '../../img/kappo-syun/ks-img00.png'
import img01 from '../../img/kappo-syun/ks-img01.png'
import img02 from '../../img/kappo-syun/ks-img02.png'
import img03 from '../../img/kappo-syun/ks-img03.png'
import img04 from '../../img/kappo-syun/ks-img04.png'
import img05 from '../../img/kappo-syun/ks-img05.png'

const Kappo = () => {
    return (
        <>
            <NavbarKs/>
            <div id="anc-kappo" className="sec-domaine">
                <div>
                    <div className="div-kappo">
                        <div className="div-domaine-bg"></div>
                        <div className="hero-dom">
                            <h1 className="kappo-h1">
                                신선한 바다의 갓포요리를 <br/>프라이빗한
                                공간에서 즐겨보세요
                            </h1>
                            <h1 className="kappo-h1 mobilet">
                                신선한 바다의 갓포요리를 프라이빗한
                                공간에서 즐겨보세요
                            </h1>
                            {/*<p className="hero-desc">*/}
                            {/*    DOMAINE CHEONGDAM*/}
                            {/*</p>*/}
                        </div>
                    </div>
                </div>
                <main className="main-dom">
                    <div className="sec-dom-gal">
                        <div className="dom-gal-top">
                            <p className="dom-gal-title">
                                TASTE<br/>OF JEJU
                            </p>
                            <p className="dom-gal-desc">
                                제주도, 동해에서 공수한 신선한 해산물로 만든 제철요리로 최고의 맛과 분위기를 내는 갓포요리식당입니다.
                                각 매력의 룸이 4개로 구성되어 있습니다.
                            </p>
                        </div>
                        <div className="dom-gal-bot">
                            <div className="dom-gallery">
                                <div className="dom-galleries-div">
                                    <img src={img00} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img01} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img02} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img03} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img04} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img05} className="dom-galleries"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="sec-dom-services">*/}
                    {/*    <div className="div-dom-services">*/}
                    {/*        <p className="dom-services-title">*/}
                    {/*            TASTE<br/>*/}
                    {/*            BY DOMAINE CHEONGDAM*/}
                    {/*        </p>*/}
                    {/*        <p className="dom-services-desc">*/}
                    {/*            프리미엄 와인들과 최고의 마리아주를 경험해보세요*/}
                    {/*        </p>*/}
                    {/*        /!*<div className="dom-services-list">*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser00} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Tasting*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser01} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Concierge Service*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser02} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Private Membership*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={ser03} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Fine Dining*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </main>

                {/* Location */}
                <div></div>

                <div className="div-loc">
                    <h2 className="loc-title">
                        매장안내
                    </h2>
                    <div className="loc-content">
                        <div className="loc-desc">
                            <p className="font-bold">
                                KAPPO SYUN by 도산대로점
                            </p>
                            <p>
                                서울 강남구 도산대로49길 18 2,3층
                            </p>
                            <p>
                                02-549-8567
                            </p>
                        </div>
                        <div className="loc-btn">
                            <a href="https://map.naver.com/v5/entry/place/1682300570?placePath=%2Fhome&c=14141267.2402661,4512409.3641147,15,0,0,0,dh"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon" />
                                <p className="text-black">
                                    네이버
                                </p>
                            </a>
                            <a
                                href="http://kko.to/X5-H9ze6Fy"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon" />
                                <p className="text-black">
                                    카카오
                                </p>
                            </a>
                            <a
                                href="https://www.google.com/maps/place/18+Dosan-daero+49-gil,+Gangnam-gu,+Seoul,+South+Korea/@37.5239963,127.0351451,17z/data=!3m1!4b1!4m5!3m4!1s0x357ca389000b462d:0x216fff7ef4fdb457!8m2!3d37.5239921!4d127.0373338"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="loc-btns"
                            >
                                <img
                                    src={IconLoc}
                                    alt="location icon"
                                    className="icon-loc"
                                />
                                <p className="text-black">
                                    구글
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div></div>
                <Footer />
            </div>
        </>
    )
}

export default Kappo