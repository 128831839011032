import DH from '../img/DH_logo.svg'

const Opening = () => {
    return (
        <div className="sec-open">
            <div className="div-open">
                <img src={DH} className="open-dh"/>
            </div>
        </div>
    )
}

export default Opening