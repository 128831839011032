import { Link } from 'react-router-dom'
import Footer from "../Footer"
import IconLoc from '../../img/icon-location-light.svg'
import NavbarDc from '../navbar/Navbar-bi'
import img00 from '../../img/1974-biraeok/bi-img00.jpeg'
import img01 from '../../img/1974-biraeok/bi-img01.jpeg'
import img02 from '../../img/1974-biraeok/bi-img02.jpeg'
import img03 from '../../img/1974-biraeok/bi-img03.jpeg'
import img04 from '../../img/1974-biraeok/bi-img04.jpeg'
import img05 from '../../img/1974-biraeok/bi-img05.jpeg'
import Arrow from '../../img/arrow-right.svg'

const Biraeok = () => {
    return (
        <>
            <NavbarDc/>
            <div id="anc-biraeok" className="sec-signal">
                <div>
                    <div className="div-signal">
                        <div className="div-dom-bg"></div>
                        <div className="hero-dom">
                            <h1 className="biraeok-h1">
                                조선시대 임금님의 입맛을 사로잡은
                                궁중보양식 맑은곰탕, 비래옥
                            </h1>
                            {/*<a href="https://www.biraeok.com/" target="_blank" className="hero-btn">*/}
                            {/*    <span className="hero-btn-txt">웹사이트</span>*/}
                            {/*    <svg className="hero-arrow" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*        <path d="M10 14L14 10L10 6L8.6 7.4L10.2 9H6V11H10.2L8.6 12.6L10 14ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z"/>*/}
                            {/*    </svg>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
                <main className="main-dom">
                    <div className="sec-dom-gal">
                        <div className="dom-gal-top">
                            <p className="dom-gal-title">
                                쓰린 속을 달래는<br/>
                                뜨끈한 위로
                            </p>
                            <p className="dom-gal-desc">
                                우수한 품질의 다양한 소고기 요리를 통해 완벽한 가심비를 선보이고
                                있는 맛집으로 알려지며 파급력 있는 행보를 선보이고 있습니다.
                            </p>
                        </div>
                        <div className="dom-gal-bot">
                            <div className="dom-gallery">
                                <div className="dom-galleries-div">
                                    <img src={img00} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img01} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img02} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img03} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img04} className="dom-galleries"/>
                                </div>
                                <div className="dom-galleries-div">
                                    <img src={img05} className="dom-galleries"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="sec-dom-services">*/}
                    {/*    <div className="div-dom-services">*/}
                    {/*        <p className="dom-services-title">*/}
                    {/*            TASTE<br/>*/}
                    {/*            BY DOMAINE CHEONGDAM*/}
                    {/*        </p>*/}
                    {/*        <p className="dom-services-desc">*/}
                    {/*            프리미엄 와인들과 최고의 마리아주를 경험해보세요*/}
                    {/*        </p>*/}
                    {/*        /!*<div className="dom-services-list">*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Tasting*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Wine Concierge Service*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Private Membership*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*    <div className="dom-services-lists">*!/*/}
                    {/*        /!*        <img src={} className="dom-services-img"/>*!/*/}
                    {/*        /!*        <p className="dom-services-type">*!/*/}
                    {/*        /!*            Fine Dining*!/*/}
                    {/*        /!*        </p>*!/*/}
                    {/*        /!*    </div>*!/*/}
                    {/*        /!*</div>*!/*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </main>

                {/* Location */}
                <div></div>

                <div className="div-loc">
                    <h2 className="loc-title">
                        매장안내
                    </h2>
                    <div className="loc-content-box">
                        <div className="loc-content">
                            <div className="loc-desc">
                                <p className="font-bold">
                                    1974 비래옥 by 방이점
                                </p>
                                <p>
                                    서울 송파구 오금로11길 16, 1층
                                </p>
                                <p>
                                    02-416-3700
                                </p>
                            </div>
                            <div className="loc-btn">
                                <a href="https://naver.me/GfMRgIld"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        네이버
                                    </p>
                                </a>
                                <a
                                    href="https://place.map.kakao.com/369158795"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        카카오
                                    </p>
                                </a>
                                <a
                                    href="https://goo.gl/maps/UvWHB2193DnvUu2p9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon"
                                        className="icon-loc"
                                    />
                                    <p className="text-black">
                                        구글
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className="loc-content">
                            <div className="loc-desc">
                                <p className="font-bold">
                                    1974 비래옥 by 압구정로데오점
                                </p>
                                <p>
                                    서울 강남구 언주로170길 35, 1층
                                </p>
                                <p>
                                    02-6958-8679
                                </p>
                            </div>
                            <div className="loc-btn">
                                <a href="https://naver.me/xvLZMAHA"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        네이버
                                    </p>
                                </a>
                                <a
                                    href="https://place.map.kakao.com/379070397"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        카카오
                                    </p>
                                </a>
                                <a
                                    href="https://goo.gl/maps/m3oKGuob9vZZdM7n9"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon"
                                        className="icon-loc"
                                    />
                                    <p className="text-black">
                                        구글
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div className="loc-content">
                            <div className="loc-desc">
                                <p className="font-bold">
                                    1974 비래옥 by 청담점
                                </p>
                                <p>
                                    서울 강남구 언주로172길 53, 1층
                                </p>
                                <p>
                                    02-2135-6988
                                </p>
                            </div>
                            <div className="loc-btn">
                                <a href="https://naver.me/xM8YVKFH"
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        네이버
                                    </p>
                                </a>
                                <a
                                    href="http://kko.to/h8XPLl0KBY"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon" />
                                    <p className="text-black">
                                        카카오
                                    </p>
                                </a>
                                <a
                                    href="https://goo.gl/maps/uBh4hwvmetUSgi4p8"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="loc-btns"
                                >
                                    <img
                                        src={IconLoc}
                                        alt="location icon"
                                        className="icon-loc"
                                    />
                                    <p className="text-black">
                                        구글
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div></div>
                <Footer />
            </div>
        </>
    )
}

export default Biraeok