import { Link } from 'react-router-dom'
import HLogo00 from "../img/logo-domaine.svg"
import HLogo01 from "../img/logo-biraeok.png"
import HLogo02 from "../img/logo-kappo.svg"
import HLogo03 from "../img/logo-cheongdam.svg"
import FooterLogo from '../img/DH_logo.svg'

const Footer = () => {

    return (
        <div className="sec-footer">
            <div className="div-footer">
                <div className="footer-top">
                    <Link to="/" className="footer-logos">
                        <img
                            height="36"
                            width="407"
                            src={FooterLogo}
                            alt="Mono F&B logo"
                            className="footer-dh"
                        />
                    </Link>
                    <div className="hor-line"></div>
                    <div className="footer-logo">
                        <a href="/domaine-cheongdam/#anc-domaine" className="footer-logos">
                            <img
                                height="60"
                                width="147"
                                src={HLogo00} alt=""
                            />
                        </a>
                        <a href="/1974-biraeok/#anc-biraeok" className="footer-logos">
                            <img
                                height="40"
                                width="158"
                                src={HLogo01} alt=""
                            />
                        </a>
                        {/*<a href="/cheongdam-haejang/#anc-cheongdam" className="footer-logos">*/}
                        {/*    <img*/}
                        {/*        height="44"*/}
                        {/*        width="148"*/}
                        {/*        src={HLogo03} alt=""/>*/}
                        {/*</a>*/}
                        <a href="/kappo-syun/#anc-kappo" className="footer-logos">
                            <img
                                height="50"
                                width="58"
                                src={HLogo02} alt=""/>
                        </a>
                    </div>
                </div>

                <div className="footer-division">

                </div>
                <div className="footer-copy">
                    <p>2023 Domaine Holdings. All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer